<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "อาชีพ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      // tableData: tableData,

      title: "อาชีพ",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "ข้อมูลหลักผู้แทนจำหน่าย",
        },
        {
          text: "อาชีพ",
          href: "/d-occupation"
        },
        {
          text: "แก้ไขอาชีพ",
          active: true,
        },
      ],
      fieldsSelect: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "branchNameTh",
          label: "ชื่อสาขาที่ใช้งานได้",
        },
        {
          key: "action",
          label: "นำออก",
        },
      ],

      fieldsMaster: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "nameTh",
          label: "ชื่อสาขา",
        },
        {
          key: "action",
          label: "เพิ่ม",
        },
      ],
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",


      branchMasterSelect: [],
      rowsBranchDfi: [],
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      occCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,

    };
  },
  validations: {
    occCode: {},
    nameTh: {
      required,
    },
    nameEn: {},
    manageBranch: {},
    shortNameTh: {},
    shortNameEn: {},
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  created() {
    this.getlocalData();
    this.showDfi();
  },
  methods: {
    getlocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      const arrayBranch = [];
      user.userAccessBranch.forEach((item) => {
        arrayBranch.push(item.branchId);
      });

      this.branchMaster = user.userAccessBranch;
      this.userBranchId = user.branchId;
      this.userBranch = arrayBranch;
      this.totalRows = this.rows.total;
    },
    /**
     * Search the table data with search input
     */
addAllBranch() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-occupation/branch/store-all", {
          occId: this.$route.params.occId,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchDFI();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    delAllBranch(){
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/d-occupation/branch/delete-all", {
          data: {
          occId: this.$route.params.occId,
          }
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchDFI();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },

    tooltipForm() {
      // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.putDfi();
      }
    },

    delBranch(branch) {
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete("api/d-occupation/branch/delete", {
          data:{
            occId: this.$route.params.occId,
          branchId: branch.branchId,
          }

        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchDFI();
          
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },


    AddBranch(branch) {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-occupation/branch/store", {
          occId: this.$route.params.occId,
          branchId: branch.branchId,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getBranchDFI();

        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    putDfi() {
      useNetw
        .put("api/d-occupation/update", {
          occId: this.$route.params.occId,
          occCode: this.occCode,
          nameTh: this.nameTh,
          nameEn: this.nameEn,
          shortNameTh: this.shortNameTh,
          shortNameEn: this.shortNameEn,
          manageBranch: this.manageBranch,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "d-occupation",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    showDfi() {
      this.overlayFlag = true;
      useNetw
        .get("api/d-occupation/show", {
          params: {
            occId: this.$route.params.occId,
          },
        })
        .then((response) => {
          // console.log("Show =>", response.data);
          this.occId = this.$route.params.occId;
          this.nameTh = response.data.data.nameTh;
          this.nameEn = response.data.data.nameEn;
          this.shortNameTh = response.data.data.shortNameTh;
          this.shortNameEn = response.data.data.shortNameEn;
          this.occCode = response.data.data.occCode;
          this.manageBranch = response.data.data.manageBranch;
          this.getBranchDFI();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getBranchDFI() {
      this.overlayFlag = true;
      this.getlocalData();
          this.branchMasterSelect = this.branchMaster;
      useNetw
        .get("api/d-occupation/branch", {
          params: {
            occId: this.$route.params.occId,
          },
        })
        .then((response) => {
          // console.log("Branch D F I  =>", response.data.branch);
          this.rowsBranchDfi = response.data.branch;

          
          this.getlocalData();
          this.branchMasterSelect = this.branchMaster;

          this.branchMasterSelect.forEach((branch, indexBranch) => {
            this.rowsBranchDfi.forEach((item) => {
              if (
                branch.branchId == item.branchId ||
                branch.nameTh == item.branchNameTh
              ) {
                
                delete this.branchMasterSelect[indexBranch];
                // this.branchMaster.splice(
                //   indexBranch,
                //   1
                // );
              }
            });
          });

          // if(!arr.includes(branch.branchId)){
          //      selectBranch.push(branch);
          //      console.log('selectBranch=>',selectBranch);
          //    }

          // console.log("non select arr", this.branchMaster);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
  },
  middleware: "authentication",
};
</script>
<style>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
.input-placeholder {
  font-size: 12px;
}
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      variant="success"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        รหัสอาชีพ <br />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="รหัสอาชีพ"
                          v-model="occCode"
                          :class="{
                            'is-invalid': submitform && $v.occCode.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.occCode.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.occCode.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อ (ไทย):
                        <b-form-input
                          v-model="nameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        ชื่อ (อังกฤษ):
                        <b-form-input
                          v-model="nameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (ไทย):
                        <b-form-input
                          v-model="shortNameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (อังกฤษ):
                        <b-form-input
                          v-model="shortNameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-1"></div>

                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        <label for="">&nbsp;</label><br />
                        <b-form-checkbox
                          v-model="manageBranch"
                          value="1"
                          unchecked-value="0"
                          class="mb-3"
                          checked
                          plain
                          :class="{
                            'is-invalid': submitform && $v.manageBranch.$error,
                          }"
                          >แก้ไขข้อมูลที่สาขา</b-form-checkbox
                        >
                        <div
                          v-if="submitform && $v.manageBranch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.manageBranch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          เสร็จสิ้น - กลับสู่หน้าหลัก
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="row"><h5>สาขาที่สามารถใช้งานอาชีพ</h5></div>
              <hr />

              <div class="row">
                <div class="col-6">
                  <label for="tb"><b>เลือกสาขาเพื่อใช้งาน</b></label>
                  <span
                    class="badge bg-success font-size-12 ms-2"
                    @click="addAllBranch()"
                  >
                    <i class="uil uil-plus"></i>เพิ่มทั้งหมด
                  </span>
                  <b-table
                    :items="branchMasterSelect"
                    :fields="fieldsMaster"
                    responsive="sm"
                    ref="selectableTable"
                    selectable
                    class="border"
                  >
                    <template #cell(index)="rowsBranchDfi">
                      {{ rowsBranchDfi.index + 1 }}
                    </template>
                    <template #cell(action)="rowsBranchDfi">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-success"
                        v-b-tooltip.hover
                        title="Add"
                        @click="AddBranch(rowsBranchDfi.item)"
                      >
                        <i class="uil uil-plus font-size-14"></i>
                      </a>
                    </template>
                  </b-table>
                </div>

                <!-- //! appv branch-->

                <div class="col-6">
                  <label for="tb"><b>สาขาที่ใข้งานได้</b></label>
                  <span
                      class="badge bg-danger font-size-12 ms-2"
                      @click="delAllBranch()"
                    >
                      <i class="uil uil-trash"></i> นำออกทั้งหมด
                    </span>
                  <b-table
                    :items="rowsBranchDfi"
                    :fields="fieldsSelect"
                    responsive="sm"
                    ref="selectableTable"
                    selectable
                    class="border"
                  >
                    <template #cell(index)="rowsBranchDfi">
                      {{ rowsBranchDfi.index + 1 }}
                    </template>
                    <template #cell(action)="rowsBranchDfi">
                      <a
                        href="javascript:void(0);"
                        class="px-2 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="delBranch(rowsBranchDfi.item)"
                      >
                        <i class="uil uil-trash font-size-14"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>
